import AriaElement from '@vo/js/modules/AriaElement'

const HeaderSearchBoxStates = Object.freeze({
    CLOSED: 'closed',
    OPEN: 'open'
})

class HeaderSearchBox {
    constructor(element) {
        this.element = element
        this.child = this.element.querySelector('[data-transition-auto-height-target]')
        this.state = HeaderSearchBoxStates.CLOSED

        this.init()
    }

    get id () {
        return this.element.id
    }

    get field() {
        return this.element.querySelector('input[type="search"]')
    }

    get toggle() {
        return document.querySelector(`[aria-controls="${this.id}"]`)
    }

    get isOpened () {
        return this.state === HeaderSearchBoxStates.OPEN
    }

    init () {
        this.bindClick(this.toggle)
        this.bindEscapePress()
        this.bindFocusOut()
        this.bindTransitionEnd()
    }

    open () {
        this.state = HeaderSearchBoxStates.OPEN
        this.updateElement()
        this.updateToggle()
    }

    close () {
        this.state = HeaderSearchBoxStates.CLOSED
        this.updateElement()
        this.updateToggle()
    }

    bindClick (toggle) {
        toggle.addEventListener('click', this.handleClick.bind(this))
    }

    bindEscapePress () {
        document.addEventListener('keydown', (event) => this.handleEscapePress.bind(this)(event))
    }

    bindFocusOut () {
        this.element.addEventListener('focusout', (event) => {
            if (!this.element.contains(event.relatedTarget)) {
                this.close()
            }
        })
    }

    bindTransitionEnd () {
        this.element.addEventListener('transitionend', this.handleTransitionEnd.bind(this))
    }

    handleClick () {
        this.isOpened ? this.close() : this.open()
    }

    handleEscapePress (event) {
        if (!this.isOpened) return
        if (event.key === 'Escape') this.close()
    }

    handleTransitionEnd () {
        if (this.isOpened) this.field.focus()
        if (!this.isOpened) this.toggle.focus()
    }

    updateToggle () {
        const toggle = new AriaElement(this.toggle)

        toggle.element.setAttribute('aria-expanded', this.isOpened ? 'true' : 'false')

        if (toggle.hasDynamicTitle) {
            toggle.titlePlaceholder.textContent = this.isOpened ? toggle.titleExpanded : toggle.titleNotExpanded
        }
    }

    updateElement () {
        this.isOpened
            ? this.element.classList.add('is-aria-expanded')
            : this.element.classList.remove('is-aria-expanded')
    }
}

export default HeaderSearchBox
